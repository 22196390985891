const config: { [id: string]: any } = {
  ethereum: {
    networkId: "0x1",
    name: "Ethereum Mainnet",
    contractAddress: "contractAbi.networks",
    symbol: "ETH",
    blockExplorer: "https://etherscan.io",
    rpcUrl: "https://mainnet.infura.io/v3/",
  },
  goerli: {
    networkId: "0x5",
    name: "Goerli",
    contractAddress: "contractAbi.networks",
    symbol: "ETH",
    blockExplorer: "https://goerli.etherscan.io",
    rpcUrl: "https://goerli.infura.io/v3/",
  },
  sepolia: {
    networkId: "0xAA36A7",
    name: "Sepolia",
    contractAddress: "0x6f2c0b7295b41a9033d6166aef03def4ac4ef1b4",
    symbol: "ETH",
    blockExplorer: "https://sepolia.etherscan.io",
    rpcUrl: "https://sepolia.infura.io/v3/",
  },
};

export const useNetwork = () => {
  // This hook works assuming you have someway of retrieving a network/chain ID from an env file
  // As well having some type of configuration file that has the information for supported network based on chainID as key
  // See above this function for file for example of a config object/file

  const walletProvider = async () => {
    console.log("walletProvider");
    if (typeof window.ethereum !== "undefined") {
      let provider = window.ethereum;
      // edge case if MM and CBW are both installed
      if (window.ethereum.providers?.length) {
        console.log("p>>>", window.ethereum.providers);

        await window.ethereum.providers.forEach(async (p: any) => {
          console.log("p>>>>", p, p.isMetaMask);
          if (p.isMetaMask) provider = p;
        });
      }

      console.log("p isMetaMask>>>>", provider.isMetaMask);

      return provider;
    }

    return null;
  };

  const connectWallet = async () => {
    console.log("connectWallet");
    const provider = await walletProvider();

    await provider.request({
      method: "eth_requestAccounts",
      params: [],
    });
  };

  const switchNetwork = async (network: string) => {
    console.log("switchNetwork");

    const cConfig = config[network] ?? {};
    const provider = await walletProvider();

    try {
      await provider?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: cConfig.networkId }],
      });
    } catch (error) {
      try {
        await provider?.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: cConfig.networkId,
              ...(cConfig.blockExplorer
                ? {
                    blockExplorerUrls: [cConfig.blockExplorer],
                  }
                : {}),
              chainName: cConfig.name,
              nativeCurrency: {
                decimals: 18,
                name: cConfig.name,
                symbol: cConfig.symbol,
              },
              rpcUrls: [cConfig.rpcUrl],
            },
          ],
        });
      } catch (error) {
        // user rejects the request to "add chain" or param values are wrong, maybe you didn't use hex above for `chainId`?
        console.log(`wallet_addEthereumChain Error: ${error}`);
        throw error;
      }
      // handle other "switch" errors
    }
  };

  return {
    connectWallet,
    switchNetwork,
  };
};
