// import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/layout";
import Home from "./pages/home";
import Callback from "./pages/callback";
import NoPage from "./pages/noPage";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path="send/*" element={<Home />} /> */}
          <Route path="callback" element={<Callback />} />
          <Route path="no_page" element={<NoPage />} />
          {/* <Route path="deposit/*" element={<Deposit />} /> */}
          {/* <Route path="deposit_magic/*" element={<DepositMagic />} /> */}
          {/* <Route path="social_login" element={<SocialLogin />} /> */}
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

/*
import { useEffect, useState } from "react";
import { config, passport } from "@imtbl/sdk";

const baseConfig = new config.ImmutableConfiguration({
  environment: config.Environment.SANDBOX,
});

const passportInstance = new passport.Passport({
  baseConfig,
  clientId: "SdUitEnEh6iFUzq38sWEbBX3MSJoPOv2",
  redirectUri: "http://localhost:5173/",
  logoutRedirectUri: "http://localhost:5173/",
  audience: "platform_api",
  scope: "openid offline_access email transact",
});

interface UserProfile {
  email?: string;
  nickname?: string;
  sub: string;
}

function App() {
  const [user, setUser] = useState<UserProfile | null>(null);

  useEffect(() => {
    passportInstance.logoutSilentCallback("/");
  }, []);

  useEffect(() => {
    passportInstance.loginCallback();
  }, []);

  async function initializeProvider() {
    const provider = await passportInstance.connectImxSilent();
    if (!provider) {
      await passportInstance.connectImx();
    }
  }

  useEffect(() => {
    async function fetchUser() {
      const userProfile = await passportInstance.getUserInfo();
      if (userProfile) {
        setUser(userProfile);
      } else {
        setUser(null);
      }
    }
    fetchUser();
  }, []);

  return (
    <div>
      {user ? (
        <>
          <h1>Welcome, {user.email}!</h1>
          <button onClick={() => passportInstance.logout()}>Logout</button>
        </>
      ) : (
        <button onClick={() => initializeProvider()}>Login</button>
      )}
    </div>
  );
}


*/
export { App };
