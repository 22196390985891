enum MetamaskAction {
  AUTHENTICATE = "authenticate",
  CHECK_IMX_BALANCE = "imx_check_imx_balance",
  TRANSFER_ETH = "imx_transfer_eth",
  TRANSFER_NFT = "imx_transfer_nft",
  TRANSFER_BATCH_NFT = "imx_transfer_batch_nft",
  IMX_BURN_NFT = "imx_burn_nft",
  IMX_BURN_BATCH_NFT = "imx_burn_batch_nft",
  LOGOUT = "logout",
  GET_METADATA = "get_metadata",
  ETH_ACCOUNTS = "eth_accounts",
  GET_ASSETS_I_OWN = "imx_get_assets_i_own",
}

export { MetamaskAction };
