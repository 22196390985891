/* tslint:disable */
/* eslint-disable */
/**
 * Immutable X API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0
 * Contact: support@immutable.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EncodeAssetRequestTokenData } from './encode-asset-request-token-data';

/**
 * 
 * @export
 * @interface EncodeAssetRequestToken
 */
export interface EncodeAssetRequestToken {
    /**
     * 
     * @type {EncodeAssetRequestTokenData}
     * @memberof EncodeAssetRequestToken
     */
    'data'?: EncodeAssetRequestTokenData;
    /**
     * The type of the token to be encoded
     * @type {string}
     * @memberof EncodeAssetRequestToken
     */
    'type'?: EncodeAssetRequestTokenTypeEnum;
}

export const EncodeAssetRequestTokenTypeEnum = {
    Eth: 'ETH',
    Erc20: 'ERC20',
    Erc721: 'ERC721'
} as const;

export type EncodeAssetRequestTokenTypeEnum = typeof EncodeAssetRequestTokenTypeEnum[keyof typeof EncodeAssetRequestTokenTypeEnum];


