/* tslint:disable */
/* eslint-disable */
/**
 * Immutable X API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0
 * Contact: support@immutable.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MetadataSchemaRequest
 */
export interface MetadataSchemaRequest {
    /**
     * Sets the metadata as filterable
     * @type {boolean}
     * @memberof MetadataSchemaRequest
     */
    'filterable'?: boolean;
    /**
     * Name of the metadata key
     * @type {string}
     * @memberof MetadataSchemaRequest
     */
    'name': string;
    /**
     * Type of the metadata. Values: \"enum\", \"text\", \"boolean\", \"continuous\", \"discrete\" | Default: \"text\". Src: https://docs.x.immutable.com/docs/asset-metadata#property-type-mapping
     * @type {string}
     * @memberof MetadataSchemaRequest
     */
    'type'?: MetadataSchemaRequestTypeEnum;
}

export const MetadataSchemaRequestTypeEnum = {
    Enum: 'enum',
    Text: 'text',
    Boolean: 'boolean',
    Continuous: 'continuous',
    Discrete: 'discrete'
} as const;

export type MetadataSchemaRequestTypeEnum = typeof MetadataSchemaRequestTypeEnum[keyof typeof MetadataSchemaRequestTypeEnum];


