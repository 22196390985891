import "./index.css";

import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import MobileDetect from "mobile-detect";
import {wagmiScreenLogo } from "../../assets/images";

function Callback() {
  const { state } = useLocation();
  const { callbackURL, data, action } = state || {};

  const md = new MobileDetect(window.navigator.userAgent);

  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isMobile) {
      try {
        if (callbackURL && callbackURL !== "") {
          window.location.href = generateLink();
        }
      } catch (e) {
        console.log("Error: " + e);
      }
    }
  }, [isMobile]);

  function init() {
    console.log("try callback init...");
    console.log("data", data);

    // const os = md.os();
    // setIsMobile(os === "iOS" || os === "AndroidOS");

    setIsMobile(md.mobile() !== null);
  }

  function generateLink() {
    console.log('clicked')
    return `${callbackURL}?data=${data}&action=${action}`;

  }

  return (
    <div className="callback-container">
    {callbackURL && callbackURL !== "" ? (
      <div>
        <div className="process-completed">Process Completed</div>
        <img src={wagmiScreenLogo} alt="Logo" className="logo-image" />
        {isMobile ? (
          <a
            href={generateLink()}
            target="_blank"
            rel="noopener noreferrer"
            className="open-app-button"
          >
            Open Game
          </a>
        ) : (
          <div className="open-app-button" >Open Game</div>
        )}
        {/* <div>and wait for a while</div> */}
        <br />
      </div>
    ) : "You can close this window"}
    </div>
  );
}


export default Callback;
