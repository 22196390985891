import {ethers as $fU9C9$ethers} from "ethers";
import $fU9C9$metamaskdetectprovider from "@metamask/detect-provider";
import $fU9C9$events from "events";
import $fU9C9$walletconnectweb3provider from "@walletconnect/web3-provider";
import {isImmutableProvider as $fU9C9$isImmutableProvider, isWalletConnection as $fU9C9$isWalletConnection, InvalidWalletConnectionError as $fU9C9$InvalidWalletConnectionError} from "@imtbl/provider-sdk-web";

function $parcel$export(e, n, v, s) {
  Object.defineProperty(e, n, {get: v, set: s, enumerable: true, configurable: true});
}
function $parcel$exportWildcard(dest, source) {
  Object.keys(source).forEach(function(key) {
    if (key === 'default' || key === '__esModule' || dest.hasOwnProperty(key)) {
      return;
    }

    Object.defineProperty(dest, key, {
      enumerable: true,
      get: function get() {
        return source[key];
      }
    });
  });

  return dest;
}
var $5c325f0e883a03a8$exports = {};

$parcel$export($5c325f0e883a03a8$exports, "L1_METAMASK_ERRORS", () => $5c325f0e883a03a8$export$3b5a68003edac880);
$parcel$export($5c325f0e883a03a8$exports, "connect", () => $5c325f0e883a03a8$export$64605811ab45167f);
$parcel$export($5c325f0e883a03a8$exports, "disconnect", () => $5c325f0e883a03a8$export$37dfea93db2e14ed);


function $f2260b792d05ad76$export$a18908926506618b(from, method, content) {}




let $3b33d7222d12f7fc$export$5ee88e2bbf22c304;
(function(WALLET_SDK_EVENTS) {
    WALLET_SDK_EVENTS["WALLET_DISCONNECTING"] = "WALLET_DISCONNECTING";
    WALLET_SDK_EVENTS["WALLET_DISCONNECTED"] = "WALLET_DISCONNECTED";
    WALLET_SDK_EVENTS["CONNECTION_UPDATED"] = "CONNECTION_UPDATED";
})($3b33d7222d12f7fc$export$5ee88e2bbf22c304 || ($3b33d7222d12f7fc$export$5ee88e2bbf22c304 = {}));
const $3b33d7222d12f7fc$export$8fe7f8574ab92ef4 = new (0, $fU9C9$events)();


function $3f173d84f674981b$var$handleChainChanged() {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "l1Provider:handleChainChanged");
    (0, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4).emit((0, $3b33d7222d12f7fc$export$5ee88e2bbf22c304).WALLET_DISCONNECTING);
}
function $3f173d84f674981b$var$handleAccountsChanged() {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "l1Provider:handleAccountsChanged");
    (0, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4).emit((0, $3b33d7222d12f7fc$export$5ee88e2bbf22c304).WALLET_DISCONNECTING);
}
function $3f173d84f674981b$var$handleClose() {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "l1Provider:handleClose");
    (0, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4).emit((0, $3b33d7222d12f7fc$export$5ee88e2bbf22c304).WALLET_DISCONNECTING);
}
function $3f173d84f674981b$export$d36f324403fca5eb(provider) {
    provider.removeListener("chainChanged", $3f173d84f674981b$var$handleChainChanged);
    provider.removeListener("accountsChanged", $3f173d84f674981b$var$handleAccountsChanged);
    provider.removeListener("close", $3f173d84f674981b$var$handleClose);
}
function $3f173d84f674981b$export$521c26d46372d93b(provider) {
    $3f173d84f674981b$export$d36f324403fca5eb(provider);
    provider.on("chainChanged", $3f173d84f674981b$var$handleChainChanged);
    provider.on("accountsChanged", $3f173d84f674981b$var$handleAccountsChanged);
    provider.on("close", $3f173d84f674981b$var$handleClose);
}



const $5c325f0e883a03a8$export$3b5a68003edac880 = {
    PROVIDER_NOT_FOUND: "The MetaMask provider was not found."
};
async function $5c325f0e883a03a8$export$64605811ab45167f({ chainID: chainID  }) {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "metamask:connect");
    const provider = await (0, $fU9C9$metamaskdetectprovider)();
    if (!provider?.request) throw new Error($5c325f0e883a03a8$export$3b5a68003edac880.PROVIDER_NOT_FOUND);
    await provider.request({
        method: (0, $c50d6d51344daa8d$export$a130407f3423b97d).CONNECT
    });
    if (chainID) await provider.request({
        method: (0, $c50d6d51344daa8d$export$a130407f3423b97d).SWITCH_CHAIN,
        params: [
            {
                chainId: `0x${chainID}`
            }
        ]
    });
    (0, $3f173d84f674981b$export$521c26d46372d93b)(provider);
    return new (0, $fU9C9$ethers).providers.Web3Provider(provider);
}
async function $5c325f0e883a03a8$export$37dfea93db2e14ed(provider) {
    (0, $3f173d84f674981b$export$d36f324403fca5eb)(provider);
}


var $d940ffb6048f913f$exports = {};

$parcel$export($d940ffb6048f913f$exports, "RPC_NOT_DEFINED", () => $d940ffb6048f913f$export$f2b3b28148959107);
$parcel$export($d940ffb6048f913f$exports, "connect", () => $d940ffb6048f913f$export$64605811ab45167f);
$parcel$export($d940ffb6048f913f$exports, "disconnect", () => $d940ffb6048f913f$export$37dfea93db2e14ed);





const $d940ffb6048f913f$export$f2b3b28148959107 = "You cannot connect to WalletConnect Provider because RPC is not defined.";
async function $d940ffb6048f913f$export$64605811ab45167f(params) {
    const { chainID: chainID , rpc: rpc  } = params;
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "walletConnect:connect");
    if (!rpc) throw new Error($d940ffb6048f913f$export$f2b3b28148959107);
    const provider = new (0, $fU9C9$walletconnectweb3provider)({
        rpc: rpc
    });
    await provider.enable();
    if (chainID) await provider.request({
        method: (0, $c50d6d51344daa8d$export$a130407f3423b97d).SWITCH_CHAIN,
        params: [
            {
                chainId: `0x${chainID}`
            }
        ]
    });
    (0, $3f173d84f674981b$export$521c26d46372d93b)(provider);
    return new (0, $fU9C9$ethers).providers.Web3Provider(provider);
}
async function $d940ffb6048f913f$export$37dfea93db2e14ed(provider) {
    (0, $3f173d84f674981b$export$d36f324403fca5eb)(provider);
    if (!provider.close) {
        (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "walletConnect:disconnect - provider is not an instance of WalletConnectProvider");
        return;
    }
    await provider.close();
}


function $340d12fcaa93adb1$export$a3679406cbb6d42(l1providerOption) {
    return `The L1 provider ${l1providerOption} is not a valid option.`;
}




let $3f3f8706bdfaf9b4$export$b10dc64d25bbcf8f;
(function(L1_PROVIDERS) {
    L1_PROVIDERS["METAMASK"] = "metamask";
    L1_PROVIDERS["WALLET_CONNECT"] = "wallet-connect";
})($3f3f8706bdfaf9b4$export$b10dc64d25bbcf8f || ($3f3f8706bdfaf9b4$export$b10dc64d25bbcf8f = {}));


const $c50d6d51344daa8d$export$a130407f3423b97d = {
    SWITCH_CHAIN: "wallet_switchEthereumChain",
    CONNECT: "eth_requestAccounts"
};


var $b3984c834cbf9651$exports = {};
var $ecc7553421318d4b$exports = {};

$parcel$export($ecc7553421318d4b$exports, "connect", () => $ecc7553421318d4b$export$64605811ab45167f);
$parcel$export($ecc7553421318d4b$exports, "getConnection", () => $ecc7553421318d4b$export$26b2f38d0fc342a3);
$parcel$export($ecc7553421318d4b$exports, "disconnect", () => $ecc7553421318d4b$export$37dfea93db2e14ed);

var $03fcce35b94b62a6$exports = {};

$parcel$export($03fcce35b94b62a6$exports, "COMMUNICATION_TYPE", () => $03fcce35b94b62a6$export$9d59ea5cffa2b1ea);
$parcel$export($03fcce35b94b62a6$exports, "REQUEST_EVENTS", () => $03fcce35b94b62a6$export$7ecc3cfb9ebd899d);
$parcel$export($03fcce35b94b62a6$exports, "RESPONSE_EVENTS", () => $03fcce35b94b62a6$export$94c7c441bb9ede13);
const $03fcce35b94b62a6$export$9d59ea5cffa2b1ea = "message";
let $03fcce35b94b62a6$export$7ecc3cfb9ebd899d;
(function(REQUEST_EVENTS) {
    REQUEST_EVENTS["GET_CONNECTION_REQUEST"] = "GET_CONNECTION_REQUEST";
    REQUEST_EVENTS["CONNECT_WALLET_REQUEST"] = "CONNECT_WALLET_REQUEST";
    REQUEST_EVENTS["SIGN_MESSAGE_REQUEST"] = "SIGN_MESSAGE_REQUEST";
    REQUEST_EVENTS["DISCONNECT_WALLET_REQUEST"] = "DISCONNECT_WALLET_REQUEST";
})($03fcce35b94b62a6$export$7ecc3cfb9ebd899d || ($03fcce35b94b62a6$export$7ecc3cfb9ebd899d = {}));
let $03fcce35b94b62a6$export$94c7c441bb9ede13;
(function(RESPONSE_EVENTS) {
    RESPONSE_EVENTS["CONNECT_WALLET_RESPONSE"] = "CONNECT_WALLET_RESPONSE";
    RESPONSE_EVENTS["SIGN_MESSAGE_RESPONSE"] = "SIGN_MESSAGE_RESPONSE";
    RESPONSE_EVENTS["GET_CONNECTION_RESPONSE"] = "GET_CONNECTION_RESPONSE";
    RESPONSE_EVENTS["DISCONNECT_WALLET_RESPONSE"] = "DISCONNECT_WALLET_RESPONSE";
})($03fcce35b94b62a6$export$94c7c441bb9ede13 || ($03fcce35b94b62a6$export$94c7c441bb9ede13 = {}));


var $2c8dba32314dfbd0$exports = {};

$parcel$export($2c8dba32314dfbd0$exports, "postRequestMessage", () => $2c8dba32314dfbd0$export$39070edfdc67f8c7);

var $200abcac381b7d82$exports = {};

$parcel$export($200abcac381b7d82$exports, "IMX_WALLET_IFRAME_ID", () => $200abcac381b7d82$export$16f8ddd8957c2047);
$parcel$export($200abcac381b7d82$exports, "IMX_WALLET_IFRAME_HOSTS", () => $200abcac381b7d82$export$32563a66ece4c3f3);
$parcel$export($200abcac381b7d82$exports, "IMX_WALLET_IFRAME_STYLE", () => $200abcac381b7d82$export$6572019be89f0f53);
$parcel$export($200abcac381b7d82$exports, "getIFrame", () => $200abcac381b7d82$export$f1597b85651e9e8);
$parcel$export($200abcac381b7d82$exports, "setupIFrame", () => $200abcac381b7d82$export$952e9ed25d572b4c);
let $919dac8482145438$export$ad0fa068a57bdc3a;
(function(ENVIRONMENTS) {
    ENVIRONMENTS["DEVELOPMENT"] = "development";
    ENVIRONMENTS["STAGING"] = "staging";
    ENVIRONMENTS["PRODUCTION"] = "production";
})($919dac8482145438$export$ad0fa068a57bdc3a || ($919dac8482145438$export$ad0fa068a57bdc3a = {}));
const $919dac8482145438$export$cda2bdaf3f0ff90c = "IMX_WALLET_MANAGER_PROVIDER_PREFERENCE";



const $200abcac381b7d82$export$16f8ddd8957c2047 = "imx-wallet-app";
const $200abcac381b7d82$export$32563a66ece4c3f3 = {
    [(0, $919dac8482145438$export$ad0fa068a57bdc3a).DEVELOPMENT]: "http://localhost:8080",
    [(0, $919dac8482145438$export$ad0fa068a57bdc3a).STAGING]: "https://wallets.sandbox.immutable.com",
    [(0, $919dac8482145438$export$ad0fa068a57bdc3a).PRODUCTION]: "https://wallets.immutable.com"
};
const $200abcac381b7d82$export$6572019be89f0f53 = "display: none;";
function $200abcac381b7d82$export$f1597b85651e9e8() {
    return document.querySelector(`iframe#${$200abcac381b7d82$export$16f8ddd8957c2047}`);
}
function $200abcac381b7d82$var$resetIFrame() {
    const iFrame = $200abcac381b7d82$export$f1597b85651e9e8();
    if (iFrame) iFrame.remove();
}
async function $200abcac381b7d82$export$952e9ed25d572b4c(env) {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "setupIFrame");
    return new Promise((resolve)=>{
        $200abcac381b7d82$var$resetIFrame();
        const iframe = document.createElement("iframe");
        iframe.setAttribute("id", $200abcac381b7d82$export$16f8ddd8957c2047);
        iframe.setAttribute("src", $200abcac381b7d82$export$32563a66ece4c3f3[env]);
        iframe.setAttribute("style", $200abcac381b7d82$export$6572019be89f0f53);
        document.body.appendChild(iframe);
        iframe.onload = ()=>resolve();
    });
}


function $2c8dba32314dfbd0$export$39070edfdc67f8c7(payload) {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "postRequestMessage", payload);
    const iFrame = (0, $200abcac381b7d82$export$f1597b85651e9e8)();
    if (iFrame && iFrame.contentWindow) iFrame.contentWindow.postMessage(payload, new URL(iFrame.src).origin);
}


var $2b091885c8a9cc92$exports = {};

$parcel$export($2b091885c8a9cc92$exports, "messageResponseListener", () => $2b091885c8a9cc92$export$2b7fdae06cf5df5f);


function $2b091885c8a9cc92$export$2b7fdae06cf5df5f(event, eventType, callback) {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "messageResponseListener", {
        event: event,
        eventType: eventType
    });
    const iFrame = (0, $200abcac381b7d82$export$f1597b85651e9e8)();
    if (iFrame && iFrame.src && event.origin !== new URL(iFrame.src).origin) return;
    const l2WalletMessage = event.data;
    if (l2WalletMessage.type !== eventType) return;
    callback(l2WalletMessage.details);
}





class $f1401eda1b4db2b6$export$492d48afe1c03e63 {
    constructor(publicAddress){
        this.publicAddress = publicAddress;
    }
    getAddress() {
        return this.publicAddress;
    }
    signMessage(rawMessage) {
        return new Promise((resolve, reject)=>{
            const listener = (event)=>{
                (0, $2b091885c8a9cc92$export$2b7fdae06cf5df5f)(event, (0, $03fcce35b94b62a6$export$94c7c441bb9ede13).SIGN_MESSAGE_RESPONSE, (messageDetails)=>{
                    window.removeEventListener((0, $03fcce35b94b62a6$export$9d59ea5cffa2b1ea), listener);
                    if (!messageDetails.success) reject(new Error(messageDetails.error?.message));
                    resolve(messageDetails.data.signedMessage);
                });
            };
            window.addEventListener((0, $03fcce35b94b62a6$export$9d59ea5cffa2b1ea), listener);
            (0, $2c8dba32314dfbd0$export$39070edfdc67f8c7)({
                type: (0, $03fcce35b94b62a6$export$7ecc3cfb9ebd899d).SIGN_MESSAGE_REQUEST,
                details: {
                    starkPublicKey: this.publicAddress,
                    message: rawMessage
                }
            });
        });
    }
}


const $ecc7553421318d4b$var$DEFAULT_CONNECTION_MESSAGE = "Only sign this request if you’ve initiated an action with Immutable X.";
const $ecc7553421318d4b$var$CONNECTION_FAILED_ERROR = "The L2 IMX Wallet connection has failed.";
async function $ecc7553421318d4b$export$64605811ab45167f(l1Provider) {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "imxWallet:connect");
    const l1Signer = l1Provider.getSigner();
    const address = await l1Signer.getAddress();
    const signature = await l1Signer.signMessage($ecc7553421318d4b$var$DEFAULT_CONNECTION_MESSAGE);
    return new Promise((resolve, reject)=>{
        const listener = (event)=>{
            (0, $2b091885c8a9cc92$export$2b7fdae06cf5df5f)(event, (0, $03fcce35b94b62a6$export$94c7c441bb9ede13).CONNECT_WALLET_RESPONSE, (messageDetails)=>{
                window.removeEventListener((0, $03fcce35b94b62a6$export$9d59ea5cffa2b1ea), listener);
                if (!messageDetails.success) reject(new Error($ecc7553421318d4b$var$CONNECTION_FAILED_ERROR));
                resolve(new (0, $f1401eda1b4db2b6$export$492d48afe1c03e63)(messageDetails.data.starkPublicKey));
            });
        };
        window.addEventListener((0, $03fcce35b94b62a6$export$9d59ea5cffa2b1ea), listener);
        (0, $2c8dba32314dfbd0$export$39070edfdc67f8c7)({
            type: (0, $03fcce35b94b62a6$export$7ecc3cfb9ebd899d).CONNECT_WALLET_REQUEST,
            details: {
                ethAddress: address,
                signature: signature
            }
        });
    });
}
async function $ecc7553421318d4b$export$26b2f38d0fc342a3(etherAddress) {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "imxWallet:getConnection");
    return new Promise((resolve)=>{
        const listener = (event)=>{
            (0, $2b091885c8a9cc92$export$2b7fdae06cf5df5f)(event, (0, $03fcce35b94b62a6$export$94c7c441bb9ede13).GET_CONNECTION_RESPONSE, (messageDetails)=>{
                window.removeEventListener((0, $03fcce35b94b62a6$export$9d59ea5cffa2b1ea), listener);
                if (!messageDetails.success) {
                    resolve(undefined);
                    return;
                }
                resolve(new (0, $f1401eda1b4db2b6$export$492d48afe1c03e63)(messageDetails.data.starkPublicKey));
            });
        };
        window.addEventListener((0, $03fcce35b94b62a6$export$9d59ea5cffa2b1ea), listener);
        (0, $2c8dba32314dfbd0$export$39070edfdc67f8c7)({
            type: (0, $03fcce35b94b62a6$export$7ecc3cfb9ebd899d).GET_CONNECTION_REQUEST,
            details: {
                etherAddress: etherAddress
            }
        });
    });
}
async function $ecc7553421318d4b$export$37dfea93db2e14ed(starkPublicKey) {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "imxWallet:disconnect");
    return new Promise((resolve)=>{
        const listener = (event)=>{
            (0, $2b091885c8a9cc92$export$2b7fdae06cf5df5f)(event, (0, $03fcce35b94b62a6$export$94c7c441bb9ede13).DISCONNECT_WALLET_RESPONSE, (messageDetails)=>{
                window.removeEventListener((0, $03fcce35b94b62a6$export$9d59ea5cffa2b1ea), listener);
                if (!messageDetails.success && messageDetails.error) (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "imxWallet:disconnect - an error happened disconnecting inside the IMX Wallet", {
                    error: messageDetails.error
                });
                resolve();
            });
        };
        window.addEventListener((0, $03fcce35b94b62a6$export$9d59ea5cffa2b1ea), listener);
        (0, $2c8dba32314dfbd0$export$39070edfdc67f8c7)({
            type: (0, $03fcce35b94b62a6$export$7ecc3cfb9ebd899d).DISCONNECT_WALLET_REQUEST,
            details: {
                starkPublicKey: starkPublicKey
            }
        });
    });
}



var $e023ba9302fd2a40$exports = {};

$parcel$export($e023ba9302fd2a40$exports, "ERROR_CODE", () => $e023ba9302fd2a40$export$fe20a4d016ee5cf3);
let $e023ba9302fd2a40$export$fe20a4d016ee5cf3;
(function(ERROR_CODE) {
    ERROR_CODE[ERROR_CODE["CANNOT_RETRIEVE_STARK_KEY_PAIR"] = 100] = "CANNOT_RETRIEVE_STARK_KEY_PAIR";
    ERROR_CODE[ERROR_CODE["GENERIC_ERROR"] = 500] = "GENERIC_ERROR";
})($e023ba9302fd2a40$export$fe20a4d016ee5cf3 || ($e023ba9302fd2a40$export$fe20a4d016ee5cf3 = {}));





$parcel$exportWildcard($b3984c834cbf9651$exports, $ecc7553421318d4b$exports);
$parcel$exportWildcard($b3984c834cbf9651$exports, $200abcac381b7d82$exports);
$parcel$exportWildcard($b3984c834cbf9651$exports, $e023ba9302fd2a40$exports);
$parcel$exportWildcard($b3984c834cbf9651$exports, $03fcce35b94b62a6$exports);
$parcel$exportWildcard($b3984c834cbf9651$exports, $2c8dba32314dfbd0$exports);
$parcel$exportWildcard($b3984c834cbf9651$exports, $2b091885c8a9cc92$exports);







let $cf29e5704b4216bf$export$1e5f60d716ee6337;
(function(L2_PROVIDERS) {
    L2_PROVIDERS["IMX_WALLET"] = "imx-wallet";
})($cf29e5704b4216bf$export$1e5f60d716ee6337 || ($cf29e5704b4216bf$export$1e5f60d716ee6337 = {}));


function $8c66120ed9b7ed4c$export$78855346ea96e8c6(l2providerOption) {
    return `The L2 provider ${l2providerOption} is not a valid option.`;
}




const $5a834aaca05753bc$var$L2SetupMap = {
    [(0, $cf29e5704b4216bf$export$1e5f60d716ee6337).IMX_WALLET]: (0, $b3984c834cbf9651$exports).setupIFrame
};
function $5a834aaca05753bc$export$de27182ff8187d6c(params) {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "setup", {
        env: params.env
    });
    const l2ProviderParam = (0, $cf29e5704b4216bf$export$1e5f60d716ee6337).IMX_WALLET;
    const setupL2 = $5a834aaca05753bc$var$L2SetupMap[l2ProviderParam];
    if (!setupL2) throw new Error((0, $8c66120ed9b7ed4c$export$78855346ea96e8c6)(l2ProviderParam));
    return setupL2(params.env);
}










const $54b5791ec8e068fb$export$c2c971b12dc5c7e2 = "PROVIDER_PREFERENCE";
const $54b5791ec8e068fb$var$hasLocalStorage = ()=>typeof window !== "undefined" && window.localStorage;
function $54b5791ec8e068fb$export$17a641110cadc1b5(l1ProviderPreference, l2ProviderPreference) {
    if ($54b5791ec8e068fb$var$hasLocalStorage()) {
        const providerPreference = `${l1ProviderPreference}:${l2ProviderPreference}`;
        (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "saveProviderPreference", {
            providerPreference: providerPreference
        });
        window.localStorage.setItem($54b5791ec8e068fb$export$c2c971b12dc5c7e2, providerPreference);
    }
}
function $54b5791ec8e068fb$export$a37bb7bdf2cf4b8d() {
    if (!$54b5791ec8e068fb$var$hasLocalStorage()) return null;
    const providerPreference = window.localStorage.getItem($54b5791ec8e068fb$export$c2c971b12dc5c7e2);
    if (!providerPreference) return null;
    const [l1ProviderPreference, l2ProviderPreference] = providerPreference.split(":");
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "getProviderPreference", {
        l1ProviderPreference: l1ProviderPreference,
        l2ProviderPreference: l2ProviderPreference
    });
    return {
        l1ProviderPreference: l1ProviderPreference,
        l2ProviderPreference: l2ProviderPreference
    };
}
function $54b5791ec8e068fb$export$961d754350de5fa1() {
    if ($54b5791ec8e068fb$var$hasLocalStorage()) {
        (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "deleteProviderPreference");
        window.localStorage.removeItem($54b5791ec8e068fb$export$c2c971b12dc5c7e2);
    }
}


const $48b784ec0f76b559$var$L1ConnectMap = {
    [(0, $3f3f8706bdfaf9b4$export$b10dc64d25bbcf8f).METAMASK]: (0, $5c325f0e883a03a8$exports).connect,
    [(0, $3f3f8706bdfaf9b4$export$b10dc64d25bbcf8f).WALLET_CONNECT]: (0, $d940ffb6048f913f$exports).connect
};
const $48b784ec0f76b559$var$L2ConnectMap = {
    [(0, $cf29e5704b4216bf$export$1e5f60d716ee6337).IMX_WALLET]: (0, $b3984c834cbf9651$exports).connect
};
async function $48b784ec0f76b559$export$64605811ab45167f(params) {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "connect", {
        params: params
    });
    const { provider: provider , ...options } = params;
    const connectL1 = $48b784ec0f76b559$var$L1ConnectMap[provider];
    if (!connectL1) throw new Error((0, $340d12fcaa93adb1$export$a3679406cbb6d42)(provider));
    const l1Provider = await connectL1(options);
    const l2ProviderParam = (0, $cf29e5704b4216bf$export$1e5f60d716ee6337).IMX_WALLET;
    const connectL2 = $48b784ec0f76b559$var$L2ConnectMap[l2ProviderParam];
    if (!connectL2) throw new Error((0, $8c66120ed9b7ed4c$export$78855346ea96e8c6)(l2ProviderParam));
    const l2Signer = await connectL2(l1Provider);
    (0, $54b5791ec8e068fb$export$17a641110cadc1b5)(provider, (0, $cf29e5704b4216bf$export$1e5f60d716ee6337).IMX_WALLET);
    return {
        l1Signer: l1Provider.getSigner(),
        l2Signer: l2Signer
    };
}








const $4996e482634eaedd$var$L1DisconnectMap = {
    [(0, $3f3f8706bdfaf9b4$export$b10dc64d25bbcf8f).METAMASK]: (0, $5c325f0e883a03a8$exports).disconnect,
    [(0, $3f3f8706bdfaf9b4$export$b10dc64d25bbcf8f).WALLET_CONNECT]: (0, $d940ffb6048f913f$exports).disconnect
};
const $4996e482634eaedd$var$L2DisconnectMap = {
    [(0, $cf29e5704b4216bf$export$1e5f60d716ee6337).IMX_WALLET]: (0, $b3984c834cbf9651$exports).disconnect
};
async function $4996e482634eaedd$var$handleL1Disconnection(l1Signer) {
    const providerPreferences = (0, $54b5791ec8e068fb$export$a37bb7bdf2cf4b8d)();
    if (!providerPreferences) return;
    const { l1ProviderPreference: l1ProviderPreference  } = providerPreferences;
    (0, $54b5791ec8e068fb$export$961d754350de5fa1)();
    const disconnectL1 = $4996e482634eaedd$var$L1DisconnectMap[l1ProviderPreference];
    if (!disconnectL1) return;
    const { provider: provider  } = l1Signer.provider;
    await disconnectL1(provider);
}
async function $4996e482634eaedd$var$handleL2Disconnection(l2Signer) {
    const l2ProviderParam = (0, $cf29e5704b4216bf$export$1e5f60d716ee6337).IMX_WALLET;
    const disconnectL2 = $4996e482634eaedd$var$L2DisconnectMap[l2ProviderParam];
    const starkPublicKey = await l2Signer.getAddress();
    await disconnectL2(starkPublicKey);
}
async function $4996e482634eaedd$export$37dfea93db2e14ed(walletConnection) {
    (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "disconnect", {
        walletConnection: walletConnection
    });
    const { l1Signer: l1Signer , l2Signer: l2Signer  } = walletConnection;
    await $4996e482634eaedd$var$handleL1Disconnection(l1Signer);
    await $4996e482634eaedd$var$handleL2Disconnection(l2Signer);
}





const $612fcc8c41f003cb$var$L1RehydrateMap = {
    [(0, $3f3f8706bdfaf9b4$export$b10dc64d25bbcf8f).METAMASK]: (0, $5c325f0e883a03a8$exports).connect,
    [(0, $3f3f8706bdfaf9b4$export$b10dc64d25bbcf8f).WALLET_CONNECT]: (0, $d940ffb6048f913f$exports).connect
};
const $612fcc8c41f003cb$var$L2RehydrateMap = {
    [(0, $cf29e5704b4216bf$export$1e5f60d716ee6337).IMX_WALLET]: (0, $b3984c834cbf9651$exports).getConnection
};
function $612fcc8c41f003cb$var$deleteProviderPreferenceAndReturn() {
    (0, $54b5791ec8e068fb$export$961d754350de5fa1)();
    return null;
}
async function $612fcc8c41f003cb$export$26b2f38d0fc342a3(params) {
    const providerPreference = (0, $54b5791ec8e068fb$export$a37bb7bdf2cf4b8d)();
    if (!providerPreference) return null;
    const { l1ProviderPreference: l1ProviderPreference , l2ProviderPreference: l2ProviderPreference  } = providerPreference;
    const getConnectionOnL1 = $612fcc8c41f003cb$var$L1RehydrateMap[l1ProviderPreference];
    if (!getConnectionOnL1) return $612fcc8c41f003cb$var$deleteProviderPreferenceAndReturn();
    const l1Provider = await getConnectionOnL1(params);
    const etherAddress = await l1Provider.getSigner().getAddress();
    const getConnectionOnL2 = $612fcc8c41f003cb$var$L2RehydrateMap[l2ProviderPreference];
    if (!getConnectionOnL2) return $612fcc8c41f003cb$var$deleteProviderPreferenceAndReturn();
    const l2Signer = await getConnectionOnL2(etherAddress);
    if (!l2Signer) return $612fcc8c41f003cb$var$deleteProviderPreferenceAndReturn();
    return {
        l1Signer: l1Provider.getSigner(),
        l2Signer: l2Signer
    };
}



class $e5226ad60433d2c0$export$988b5c7321caf96b {
    constructor({ rpc: rpc , chainID: chainID  }){
        this.rpc = rpc;
        this.chainID = chainID;
        this.handleImxEvents();
    }
    handleImxEvents() {
        (0, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4).on((0, $3b33d7222d12f7fc$export$5ee88e2bbf22c304).WALLET_DISCONNECTING, ()=>{
            this.disconnect();
        });
    }
    async connect(params) {
        const connectionResult = await (0, $48b784ec0f76b559$export$64605811ab45167f)({
            ...params,
            rpc: this.rpc,
            chainID: this.chainID
        });
        this.walletConnection = connectionResult;
        (0, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4).emit((0, $3b33d7222d12f7fc$export$5ee88e2bbf22c304).CONNECTION_UPDATED, this.walletConnection);
        return connectionResult;
    }
    isConnected() {
        return !!this.walletConnection;
    }
    async getWalletConnection() {
        if (this.isConnected()) return this.walletConnection;
        const connection = await (0, $612fcc8c41f003cb$export$26b2f38d0fc342a3)({
            rpc: this.rpc,
            chainID: this.chainID
        });
        if (connection) {
            this.walletConnection = connection;
            (0, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4).emit((0, $3b33d7222d12f7fc$export$5ee88e2bbf22c304).CONNECTION_UPDATED, this.walletConnection);
            return connection;
        }
        return null;
    }
    async disconnect() {
        if (this.walletConnection) {
            (0, $4996e482634eaedd$export$37dfea93db2e14ed)(this.walletConnection);
            (0, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4).emit((0, $3b33d7222d12f7fc$export$5ee88e2bbf22c304).WALLET_DISCONNECTED);
        }
    }
    static async build(params) {
        await (0, $5a834aaca05753bc$export$de27182ff8187d6c)(params);
        const { rpc: rpc , chainID: chainID  } = params;
        const sdk = new $e5226ad60433d2c0$export$988b5c7321caf96b({
            rpc: rpc,
            chainID: chainID
        });
        // keep it async, it would fire the events once the walletconnection is updated
        sdk.getWalletConnection();
        return sdk;
    }
}






class $8649a59475a8fd39$export$d9a919bae0329c9a {
    baseWallets = [];
    constructor(){
        this.handleImxEvents();
    }
    handleImxEvents() {
        (0, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4).on((0, $3b33d7222d12f7fc$export$5ee88e2bbf22c304).WALLET_DISCONNECTING, ()=>{
            this.disconnect();
        });
    }
    hasLocalStorage() {
        return typeof window !== "undefined" && window.localStorage;
    }
    isConnected() {
        return !!this.walletConnection;
    }
    async disconnect() {
        if (this.hasLocalStorage()) {
            const store = window.localStorage;
            const providerPreference = store.getItem((0, $919dac8482145438$export$cda2bdaf3f0ff90c));
            store.removeItem((0, $919dac8482145438$export$cda2bdaf3f0ff90c));
            (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "providerPreferenceCleared", {
                providerPreference: providerPreference
            });
        }
        if (this.walletConnection) {
            this.walletConnection = null;
            (0, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4).emit((0, $3b33d7222d12f7fc$export$5ee88e2bbf22c304).WALLET_DISCONNECTED);
        }
    }
    getWalletsFromWindow() {
        const validWallets = [];
        const windowProviders = window.imxProviders || [];
        return [
            windowProviders
        ].flat().reduce((acc, v)=>{
            if ((0, $fU9C9$isImmutableProvider)(v)) acc.push(v);
            return acc;
        }, validWallets);
    }
    persistConnection(connectParams, walletConnection) {
        if (!this.hasLocalStorage()) return;
        const providerPreference = {
            providerName: walletConnection.providerName,
            ...connectParams
        };
        (0, $f2260b792d05ad76$export$a18908926506618b)("sdk", "saveProviderPreference", {
            providerPreference: providerPreference
        });
        window.localStorage.setItem((0, $919dac8482145438$export$cda2bdaf3f0ff90c), JSON.stringify(providerPreference));
    }
    async reconnectWallet() {
        if (!this.hasLocalStorage()) return null;
        const providerPreference = window.localStorage.getItem((0, $919dac8482145438$export$cda2bdaf3f0ff90c));
        if (!providerPreference) return null;
        const { providerName: providerName , chainID: chainID , rpc: rpc  } = JSON.parse(providerPreference);
        const provider = this.getAvailableWallets().find((prov)=>prov.providerName === providerName);
        return provider && this.enrichedConnect({
            chainID: chainID,
            rpc: rpc
        }, provider.connect);
    }
    async enrichedConnect(connectParams, providerConnectFunc) {
        const connection = await providerConnectFunc(connectParams);
        if (!(0, $fU9C9$isWalletConnection)(connection)) throw new (0, $fU9C9$InvalidWalletConnectionError)();
        this.walletConnection = connection;
        this.persistConnection(connectParams, this.walletConnection);
        (0, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4).emit((0, $3b33d7222d12f7fc$export$5ee88e2bbf22c304).CONNECTION_UPDATED, this.walletConnection);
        return this.walletConnection;
    }
    getAvailableWallets() {
        const enrConn = this.enrichedConnect.bind(this);
        return [
            ...this.baseWallets,
            ...this.getWalletsFromWindow()
        ].map((w)=>({
                ...w,
                async connect (params) {
                    return enrConn(params, w.connect);
                }
            }));
    }
    async getWalletConnection() {
        if (this.walletConnection) return this.walletConnection;
        return this.reconnectWallet();
    }
}






export {$3f3f8706bdfaf9b4$export$b10dc64d25bbcf8f as L1_PROVIDERS, $f2260b792d05ad76$export$a18908926506618b as addLog, $e5226ad60433d2c0$export$988b5c7321caf96b as WalletSDK, $8649a59475a8fd39$export$d9a919bae0329c9a as WalletManager, $3b33d7222d12f7fc$export$5ee88e2bbf22c304 as WALLET_SDK_EVENTS, $3b33d7222d12f7fc$export$8fe7f8574ab92ef4 as walletSdkEvents, $919dac8482145438$export$ad0fa068a57bdc3a as ENVIRONMENTS, $ecc7553421318d4b$export$64605811ab45167f as connect, $ecc7553421318d4b$export$26b2f38d0fc342a3 as getConnection, $ecc7553421318d4b$export$37dfea93db2e14ed as disconnect, $200abcac381b7d82$export$16f8ddd8957c2047 as IMX_WALLET_IFRAME_ID, $200abcac381b7d82$export$32563a66ece4c3f3 as IMX_WALLET_IFRAME_HOSTS, $200abcac381b7d82$export$6572019be89f0f53 as IMX_WALLET_IFRAME_STYLE, $200abcac381b7d82$export$f1597b85651e9e8 as getIFrame, $200abcac381b7d82$export$952e9ed25d572b4c as setupIFrame, $e023ba9302fd2a40$export$fe20a4d016ee5cf3 as ERROR_CODE, $03fcce35b94b62a6$export$9d59ea5cffa2b1ea as COMMUNICATION_TYPE, $03fcce35b94b62a6$export$7ecc3cfb9ebd899d as REQUEST_EVENTS, $03fcce35b94b62a6$export$94c7c441bb9ede13 as RESPONSE_EVENTS, $2c8dba32314dfbd0$export$39070edfdc67f8c7 as postRequestMessage, $2b091885c8a9cc92$export$2b7fdae06cf5df5f as messageResponseListener};
