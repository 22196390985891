function $parcel$export(e, n, v, s) {
  Object.defineProperty(e, n, {get: v, set: s, enumerable: true, configurable: true});
}
let $919dac8482145438$export$ad0fa068a57bdc3a;
(function(ENVIRONMENTS) {
    ENVIRONMENTS["DEVELOPMENT"] = "development";
    ENVIRONMENTS["STAGING"] = "staging";
    ENVIRONMENTS["PRODUCTION"] = "production";
})($919dac8482145438$export$ad0fa068a57bdc3a || ($919dac8482145438$export$ad0fa068a57bdc3a = {}));


var $ca3cf2dd060153b0$exports = {};


var $73a5846e272638e9$exports = {};

$parcel$export($73a5846e272638e9$exports, "isImmutableProvider", () => $73a5846e272638e9$export$e8968360d743e270);
$parcel$export($73a5846e272638e9$exports, "isWalletConnection", () => $73a5846e272638e9$export$a87ea8a5b34ce57e);
const $73a5846e272638e9$var$iconRegex = /^data:image\/(jpeg|png|svg|webp);base64,.*/;
function $73a5846e272638e9$var$validMetadata(obj) {
    return "providerName" in obj && typeof obj.providerName === "string" && "providerIcon" in obj && typeof obj.providerIcon === "string" && $73a5846e272638e9$var$iconRegex.test(obj.providerIcon);
}
function $73a5846e272638e9$export$e8968360d743e270(obj) {
    return $73a5846e272638e9$var$validMetadata(obj) && "connect" in obj && typeof obj.connect === "function" && obj.connect.length === 1;
}
function $73a5846e272638e9$export$a87ea8a5b34ce57e(obj) {
    return $73a5846e272638e9$var$validMetadata(obj) && "signers" in obj && typeof obj.signers === "object" && Object.keys(obj.signers).length > 0;
}


var $86aa41f7077c658b$exports = {};

$parcel$export($86aa41f7077c658b$exports, "InvalidWalletConnectionError", () => $86aa41f7077c658b$export$3a4197a3570db927);
$parcel$export($86aa41f7077c658b$exports, "ConnectionRejectedError", () => $86aa41f7077c658b$export$5e16462054388cf5);
$parcel$export($86aa41f7077c658b$exports, "IntegrityCheckFailedError", () => $86aa41f7077c658b$export$fe0d31b60232d46);
$parcel$export($86aa41f7077c658b$exports, "TransactionRejectedError", () => $86aa41f7077c658b$export$bfa1d431f968445f);
/* eslint-disable max-classes-per-file */ class $86aa41f7077c658b$export$3a4197a3570db927 extends Error {
}
class $86aa41f7077c658b$export$5e16462054388cf5 extends Error {
}
class $86aa41f7077c658b$export$fe0d31b60232d46 extends Error {
}
class $86aa41f7077c658b$export$bfa1d431f968445f extends Error {
}




export {$919dac8482145438$export$ad0fa068a57bdc3a as ENVIRONMENTS, $73a5846e272638e9$export$e8968360d743e270 as isImmutableProvider, $73a5846e272638e9$export$a87ea8a5b34ce57e as isWalletConnection, $86aa41f7077c658b$export$3a4197a3570db927 as InvalidWalletConnectionError, $86aa41f7077c658b$export$5e16462054388cf5 as ConnectionRejectedError, $86aa41f7077c658b$export$fe0d31b60232d46 as IntegrityCheckFailedError, $86aa41f7077c658b$export$bfa1d431f968445f as TransactionRejectedError};
