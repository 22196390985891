// import logo from "./logo.svg";
import "./index.css";
import { useEffect, useState, useRef } from "react";
import { Base64 } from "js-base64";

import md5 from "crypto-js/md5";

import { useNavigate } from "react-router-dom";

import {
  ENVIRONMENTS,
  L1_PROVIDERS,
  WALLET_SDK_EVENTS,
  WalletSDK,
  WalletConnection,
  walletSdkEvents,
} from "@imtbl/wallet-sdk-web";

import {
  ImmutableX,
  Config,
  IMXError,
  BalancesApiGetBalanceRequest,
  UnsignedTransferRequest,
  NftTransferDetails,
} from "@imtbl/core-sdk";

import { BigNumber, ethers } from "ethers";

import { wagmiLogo, walletIcon, ethIcon } from "../../assets/images";
import { MetamaskAction } from "../../enums";

import { useNetwork } from "../../helpers/useNetwork";

import { delay } from "../../utils";

function Home() {
  // const [isDebug, setIsDebug] = useState<boolean>(false);

  const walletSDKRef = useRef<WalletSDK | null>(null);
  const imxClientRef = useRef<ImmutableX | null>(null);
  const envRef = useRef<string>("development");

  const [walletConnection, setWalletConnection] =
    useState<WalletConnection | null>(null);

  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [walletBalance, setWalletBalance] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const querySearchParamsRef = useRef<any | null>();

  const [messageDialog, setMessageDialog] = useState<any | null>(null);

  const navigate = useNavigate();

  const network = useNetwork();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    updateWalletAddress();
  }, [walletConnection]);

  useEffect(() => {
    handleAction();
  }, [walletAddress]);

  async function init() {
    console.log("v8");

    const querySearch = new URLSearchParams(window.location.search);

    const querySearchParams = querySearch.get("params") ?? "";
    // const debug = (querySearch.get("debug") ?? "false") === "true";

    console.log(
      "querySearchParams",
      querySearchParams,
      window.location.hostname
    );

    if (querySearchParams && querySearchParams !== "") {
      try {
        // {"host":"http://localhost:3000","sdk":"magic-sdk-unity","API_KEY":"pk_live_EFB1946C79BADEE2","locale":"en-US","bundleId":"com.DefaultCompany.MagicLinkTest","ETH_NETWORK":"goerli"}
        const params = JSON.parse(Base64.decode(querySearchParams));

        querySearchParamsRef.current = params;

        console.log("params: " + JSON.stringify(params));

        await initIMX(params);
      } catch (error) {
        console.error(error);
        navigate("no_page");
      }
    } else if (window.location.hostname === "localhost") {
      const params = {
        env: ENVIRONMENTS.PRODUCTION,
        action: MetamaskAction.AUTHENTICATE,
        data: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VJZCI6IjQ4NUM2N0JGLThCQjEtNEYwNS04M0ZFLTZCOTk3MzQzNDI4MyIsImlhdCI6MTcwMDU1MTY2MH0.KZMopbHekDWiV1nB6vE40YyhMQlTa7yJ8WBMN-oFFKs",
        callbackURL: "",
      }; // for testing

      querySearchParamsRef.current = params;

      await initIMX(params);
    } else {
      navigate("no_page");
    }
  }

  async function initIMX(params: any) {
    setIsLoading(true);

    const { env } = params;

    envRef.current = env;

    const imxConfig = env === "production" ? Config.PRODUCTION : Config.SANDBOX;
    console.log("imxConfig: " + JSON.stringify(imxConfig));

    await switchNetwork(env);

    const sdk = await WalletSDK.build({ env });

    walletSDKRef.current = sdk;

    const imxClient = new ImmutableX(imxConfig);
    imxClientRef.current = imxClient;

    addEventListeners();

    await delay(2000); // 2 seconds deley so that listener may he triggered

    setIsLoading(false);
  }

  async function switchNetwork(env: string) {
    try {
      await network.switchNetwork(
        env === "production" ? "ethereum" : "sepolia"
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  function addEventListeners() {
    // Listens for connection update notifications and when triggered, updates the state
    walletSdkEvents.on(
      WALLET_SDK_EVENTS.CONNECTION_UPDATED,
      (updatedWalletConnection: WalletConnection) => {
        setWalletConnection(updatedWalletConnection);
      }
    );

    // Listens for disconnection notifications and when triggered, cleans the state up
    walletSdkEvents.on(WALLET_SDK_EVENTS.WALLET_DISCONNECTED, () => {
      setWalletConnection(null);
    });
  }

  async function connectWallet() {
    if (!walletSDKRef.current || !imxClientRef.current) {
      console.log("Wallet SDK not initialized");

      return;
      throw new Error("Wallet SDK not initialized");
    }

    try {
      setIsLoading(true);
      // Call the method

      // Connects on the chosen provider - WalletConnect
      // const walletConnection = await sdk.connect({
      //   provider: L1_PROVIDERS.WALLET_CONNECT,
      // });

      const status = await switchNetwork(envRef.current);

      if (!status) {
        setMessageDialog({ message: "Unable to switch network" });
        setIsLoading(false);
        return;
      }

      // For Metamask:
      //const walletConnectionIns =
      await walletSDKRef.current.connect({
        provider: L1_PROVIDERS.METAMASK,
      });

      // Print the result
      console.log("CONNECTED");

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      // Catch and print out the error
      console.error(error);

      if (error instanceof Error) {
        // throw new Error(error.message);
        setMessageDialog({ errorMessage: error.message });
      } else {
        // handle other errors
        // throw new Error("Unkown Error");

        setMessageDialog({ message: "Unkown Error" });
      }
    }
  }

  async function needToRegister() {
    if (!imxClientRef.current || !walletAddress || !walletConnection) {
      throw new Error("Wallet is not connected");
    }

    try {
      const user = await imxClientRef.current.getUser(walletAddress);

      if (user.accounts.length) {
        console.log("User is already registered...");
      }
    } catch (e) {
      console.error(e);
      if (e instanceof IMXError) {
        try {
          console.log("User not registered. Need to register user");
          await imxClientRef.current.registerOffchain({
            ethSigner: walletConnection.l1Signer,
            starkSigner: walletConnection.l2Signer,
          });
          console.log("User has successfully been registered");
        } catch {
          throw new Error("Error in registerOffchain");
        }
      } else {
        throw new Error("Error in user registration");
      }
    }
  }

  async function disconnectWallet() {
    if (!walletSDKRef.current) {
      throw new Error("Wallet SDK not initialized");
    }

    try {
      setIsLoading(true);

      setMessageDialog(null);

      await walletSDKRef.current.disconnect();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      // Catch and print out the error
      console.error(error);

      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        // handle other errors
        throw new Error("Unkown Error");
      }
    }
  }

  async function updateWalletAddress() {
    if (walletConnection) {
      const walletAddressIns = await walletConnection.l1Signer.getAddress();
      setWalletAddress(walletAddressIns);

      const balance = await walletConnection.l1Signer.getBalance();
      const ethBalance = ethers.utils.formatEther(balance);

      setWalletBalance(ethBalance);
    } else {
      setWalletAddress(null);
      setWalletBalance(null);
    }
  }

  async function getWalletIMXBalance() {
    if (!imxClientRef.current || !walletAddress || !walletConnection) {
      throw new Error("Wallet is not connected");
    }

    const client: ImmutableX = imxClientRef.current;

    // Get balance of the users wallet
    const balanceAPIRequest: BalancesApiGetBalanceRequest = {
      owner: walletAddress,
      address: "ETH",
    };

    const balance = await client.getBalance(balanceAPIRequest);

    const ethBalance = ethers.utils.formatEther(
      BigNumber.from(balance.balance)
    );

    return ethBalance;
  }

  //create an ERC721 transfer on IMX
  async function createERC721Transfer(
    tokenId: string,
    tokenAddress: string,
    transferTo: string
  ) {
    if (!imxClientRef.current || !walletAddress || !walletConnection) {
      throw new Error("Wallet is not connected");
    }

    // Assemble ERC721 Unsigned Transfer request
    const transferRequest: UnsignedTransferRequest = {
      receiver: transferTo.toLowerCase(),
      type: "ERC721",
      tokenId: tokenId,
      tokenAddress: tokenAddress.toLowerCase(),
    };

    return imxClientRef.current.transfer(
      {
        ethSigner: walletConnection.l1Signer,
        starkSigner: walletConnection.l2Signer,
      },
      transferRequest
    );
  }

  async function createBatchNftTransfer(
    bulkTransferRequest: Array<NftTransferDetails>
  ) {
    if (!imxClientRef.current || !walletAddress || !walletConnection) {
      throw new Error("Wallet is not connected");
    }

    return imxClientRef.current.batchNftTransfer(
      {
        ethSigner: walletConnection.l1Signer,
        starkSigner: walletConnection.l2Signer,
      },
      bulkTransferRequest
    );
  }

  //Transfer an NFT from users wallet to a target wallet
  async function doNFTBurn(tokenId: string, tokenAddress: string) {
    const transferTo = "0x0000000000000000000000000000000000000000"; // Ethereum burn account

    return createERC721Transfer(tokenId, tokenAddress, transferTo);
  }

  async function doBatchNFTBurn(tokens: Array<any>) {
    const transferTo = "0x0000000000000000000000000000000000000000"; // Ethereum burn account

    const bulkTransferRequest: Array<NftTransferDetails> = tokens.map(
      (token: any) => {
        return {
          receiver: transferTo,
          tokenId: token.tokenId,
          tokenAddress: token.tokenAddress,
        };
      }
    );

    return createBatchNftTransfer(bulkTransferRequest);
  }

  //create an ETH transfer on IMX
  async function createETHTransfer(receiver: string, amount: string) {
    if (!imxClientRef.current || !walletAddress || !walletConnection) {
      throw new Error("Wallet is not connected");
    }

    // Assemble Eth Unsigned Transfer request
    const transferRequest: UnsignedTransferRequest = {
      receiver: receiver.toLowerCase(),
      type: "ETH",
      amount: amount,
    };
    return imxClientRef.current.transfer(
      {
        ethSigner: walletConnection.l1Signer,
        starkSigner: walletConnection.l2Signer,
      },
      transferRequest
    );
  }

  async function signRequest(data: any) {
    if (!imxClientRef.current || !walletAddress || !walletConnection) {
      throw new Error("Wallet is not connected");
    }

    console.log("data", data);

    const md5Data = md5(data);

    const hash = await walletConnection.l1Signer.signMessage(
      md5Data.toString()
    );
    return hash;
  }

  async function handleAction() {
    if (!imxClientRef.current || !walletAddress || !walletConnection) {
      return;
    }

    try {
      setIsLoading(true);
      await needToRegister();

      console.log("querySearchParamsRef.current", querySearchParamsRef.current);

      const params = querySearchParamsRef.current;

      const { action, callbackURL } = params;

      switch (action) {
        case MetamaskAction.AUTHENTICATE: {
          const status = await switchNetwork(envRef.current);

          if (!status) {
            setMessageDialog({ message: "Unable to switch network" });
            return;
          }

          const { data } = params;

          const hash = await signRequest(data);

          console.log("hash", hash);

          navigate("callback", {
            state: {
              callbackURL,
              action: action,
              data: Base64.encode(
                JSON.stringify({
                  hash,
                  token: data,
                  walletAddress,
                })
              ),
            },
          });

          break;
        }

        case MetamaskAction.CHECK_IMX_BALANCE: {
          const imxBalance = await getWalletIMXBalance();
          console.log("imxBalance", imxBalance);

          navigate("callback", {
            state: {
              callbackURL,
              action: action,
              data: Base64.encode(
                JSON.stringify({
                  imxBalance,
                })
              ),
            },
          });

          break;
        }

        case MetamaskAction.TRANSFER_NFT: {
          const { tokenId, tokenAddress, transferTo } = params;

          const result = await createERC721Transfer(
            tokenId,
            tokenAddress,
            transferTo
          );

          console.log("result: " + JSON.stringify(result));

          navigate("callback", {
            state: {
              callbackURL,
              action: action,
              data: Base64.encode(
                JSON.stringify({
                  ...result,
                })
              ),
            },
          });

          break;
        }

        case MetamaskAction.TRANSFER_BATCH_NFT: {
          const { bulkTransfer } = params;

          const result = await createBatchNftTransfer(bulkTransfer);

          console.log("result: " + JSON.stringify(result));

          navigate("callback", {
            state: {
              callbackURL,
              action: action,
              data: Base64.encode(
                JSON.stringify({
                  ...result,
                })
              ),
            },
          });

          break;
        }

        case MetamaskAction.IMX_BURN_NFT: {
          const { tokenId, tokenAddress } = params;

          const result = await doNFTBurn(tokenId, tokenAddress);

          console.log("result: " + JSON.stringify(result));

          navigate("callback", {
            state: {
              callbackURL,
              action: action,
              data: Base64.encode(
                JSON.stringify({
                  ...result,
                })
              ),
            },
          });

          break;
        }

        case MetamaskAction.IMX_BURN_BATCH_NFT: {
          const { tokens } = params;

          const result = await doBatchNFTBurn(tokens);

          console.log("result: " + JSON.stringify(result));

          navigate("callback", {
            state: {
              callbackURL,
              action: action,
              data: Base64.encode(
                JSON.stringify({
                  ...result,
                })
              ),
            },
          });

          break;
        }

        case MetamaskAction.TRANSFER_ETH: {
          const { receiver, amount } = params;

          const amountToTransfer = `${ethers.utils.parseEther(amount)}`;

          console.log("try amountToTransfer: " + amountToTransfer);

          const result = await createETHTransfer(receiver, amountToTransfer);

          console.log("result: " + JSON.stringify(result));

          navigate("callback", {
            state: {
              callbackURL,
              action: action,
              data: Base64.encode(
                JSON.stringify({
                  ...result,
                })
              ),
            },
          });

          break;
        }

        default: {
          navigate("callback", {
            state: {
              callbackURL,
              data: "",
            },
          });
          break;
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      setMessageDialog({
        errorMessage: (error as Error)?.message ?? "Unkown Error",
        message: "Please try again",
        actionTitle: "Retry",
        action: () => {
          handleAction();
        },
      });
    }
  }

  const renderHeader = () => {
    const shortAddress = walletAddress
      ? `${walletAddress.slice(0, 6)}...${walletAddress.slice(
          walletAddress.length - 4,
          walletAddress.length
        )}`
      : null;

    return (
      <div className="header">
        <img
          className="logo"
          alt="Wagmi games"
          src={wagmiLogo}
          decoding="async"
          data-nimg="intrinsic"
          // style="position:absolute;top:0;left:0;bottom:0;right:0;box-sizing:border-box;padding:0;border:none;margin:auto;display:block;width:0;height:0;min-width:100%;max-width:100%;min-height:100%;max-height:100%"
          // srcset="/_next/image?url=%2Fimages%2Fwagmi_logo.png&amp;w=128&amp;q=75 1x, /_next/image?url=%2Fimages%2Fwagmi_logo.png&amp;w=256&amp;q=75 2x"
        />
        {walletAddress && walletBalance && (
          <div className="walletContainer">
            <div className="walletBalance">
              <img className="ethIcon" src={ethIcon} />
              {`${
                walletBalance.length > 10
                  ? walletBalance.slice(0, 10) + "..."
                  : walletBalance
              }`}
            </div>
            <button
              className="walletAddressButton"
              onClick={async () => {
                await navigator.clipboard.writeText(walletAddress);

                alert("Copied the text: " + walletAddress);
              }}
            >
              <img className="walletIcon" src={walletIcon} />

              <div className="wallet">{shortAddress}</div>
            </button>
          </div>
        )}
      </div>
    );
  };

  const renderSignup = () => {
    const { errorMessage } = messageDialog ?? {};
    return (
      <>
        {renderHeader()}
        <div className="content">
          {/* <h1>Connect Wallet</h1> */}

          <div className="dialog">
            <h1 className="dialog-title">Connect Wallet</h1>
            <button
              // className="generalButton"
              type="button"
              onClick={() => {
                // magicAuthLoginWithRedirect("google");
                console.log("Connect Wallet");
                connectWallet();
              }}
            >
              Connect
            </button>
          </div>

          {errorMessage}
        </div>
      </>
    );
  };

  const renderMessage = () => {
    const { title, message, errorMessage, actionTitle, action } =
      messageDialog ?? {};

    return (
      <>
        {renderHeader()}
        <div className="content">
          {title && <h1>{title}</h1>}
          <div className="dialog">
            {message && <h1 className="dialog-sub-title">{message}</h1>}
            {action && actionTitle && (
              <button
                onClick={() => {
                  // setDepositInfo("");
                  action();
                }}
              >
                {actionTitle}
              </button>
            )}
          </div>
        </div>

        {errorMessage}

        <button className="generalButton" onClick={() => disconnectWallet()}>
          Disconnect
        </button>
      </>
    );
  };

  const renderLoader = () => {
    return (
      <>
        {renderHeader()}
        <div className="info-content">
          <div className="loader"></div>
        </div>
      </>
    );
  };

  const renderWaiting = () => {
    return (
      <>
        {renderHeader()}
        <div className="info-content">
          <h1>Please Wait...</h1>
        </div>
      </>
    );
  };

  return (
    <div className="container">
      {isLoading
        ? renderLoader()
        : walletConnection &&
          walletSDKRef.current &&
          walletSDKRef.current.isConnected()
        ? messageDialog
          ? renderMessage()
          : renderWaiting()
        : renderSignup()}
    </div>
  );
}

export default Home;
